<template>
    <zw-sidebar @shown="shown" :title="$t('common.title.scan')">
        <textarea class="console"
                  v-model="console"
                  readonly
                  ref="console"
        ></textarea>
        <b-input type="text" class="mb-2" @keyup.enter="manualEnter" v-model="manualCode"/>
        <b-button variant="primary"
                  @click="manualEnter"
                  class="mr-2"
        >Manual enter
        </b-button>
        <b-button @click.prevent="$root.$children[0].openModal('search-offering-modal', {},searchBarCode)"
                  variant="info">Search
        </b-button>
    </zw-sidebar>
</template>

<script>
export default {
    name: 'ScanModal',
    data() {
        return {
            payload: {},
            manualCode: '',
            code: '',
            reading: false,
            console: '',
            successCode: null,
        }
    },
    created() {
        window.addEventListener('keypress', this.onKeyPress);
    },
    beforeDestroy() {
        window.removeEventListener('keypress', this.onKeyPress);
    },
    methods: {
        shown() {
        },
        searchBarCode(id) {
            this.getLabel('offering_id_' + id);
        },
        getLabel(code) {
            this.printToConsole('Getting label...')
            this.$store.dispatch('Barcode/getOffering', code)
                .then(response => {
                    if (response.data.success == false) {
                        this.printToConsole('Response: ' + response.data.message)
                    } else if (response.data.success) {
                        this.printToConsole('Data found (' + response.data.type + ')!')
                        this.successCode = code

                        if (response.data.type == 'article') {
                            this.$root.$children[0].openModal('article-modal', {id: response.data.data.id})
                        } else {
                            this.$root.$children[0].openModal('kva-modal', {
                                id: response.data.data.id,
                                formType: this.payload.formType || null
                            })
                        }
                    }
                })
        },
        manualEnter() {
            this.printToConsole('Manually entered code: ' + this.manualCode)
            this.getLabel(this.manualCode)
            this.manualCode = ''
        },
        onKeyPress(e) {
            if (e.key === 'Enter') {
                if (this.code.length > 6) {
                    this.printToConsole('Scanned code: ' + this.code)
                    this.getLabel(this.code)
                    this.code = '';
                }
            } else {
                this.code += e.key;
            }

            if (!this.reading) {
                this.reading = true;
                setTimeout(() => {
                    this.code = '';
                    this.reading = false;
                }, 200);
            }
        },
        printToConsole(text) {
            let current = new Date();
            this.console += current.toLocaleTimeString() + ': ' + text + '\n'

            this.$refs.console.scrollTop = this.$refs.console.scrollHeight
        }
    },
}
</script>

<style>
.console {
    width: 100%;
    height: 300px;
    background: black;
    color: white;
}
</style>